<template>
  <div class="marketMall">
    <div class="breadcrumb">
      <div class="container flex flex-vc">
        <span class="homePath" @click="$router.push('/')">{{
          $t("marketMall.homeName")
        }}</span>
        <img src="@/assets/marketMall/icon_arrow.png" alt="" />
        <span>行情商城</span>
        <img src="@/assets/marketMall/icon_arrow.png" alt="" />
        <span>{{ market === "us" ? "美股" : "港股" }}</span>
      </div>
    </div>
    <keep-alive :include="cachePages">
      <router-view></router-view>
      <!--这里是会被缓存的组件-->
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "MarketMall",
  computed: {
    market() {
      return this.$route.query.market || "hk";
    },
  },
  data() {
    return {
      cachePages: [],
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.marketMall {
  .page {
    &-enter {
      opacity: 0;
      transform: translateX(-30px);
    }

    &-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }

    &-enter-active,
    &-leave-active {
      transition: all 0.3s ease;
    }
  }
}
</style>
